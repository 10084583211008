import React from "react"

export default function NotificationHeroComponent() {
  return (
    <div className="flex items-center justify-center gap-6">
      {/*<!-- Component: Title & text dark themed notification with action close buttons and leading icon --> */}
      <div
        className="relative flex flex-col max-w-full px-4 py-3 overflow-hidden text-sm rounded shadow-lg w-80 bg-slate-700 text-slate-200 shadow-slate-500/20 ring-1 ring-inset ring-slate-900"
        role="status"
      >
        {/*  <!-- Heading with icon & close button --> */}
        <div className="flex items-center gap-4 mb-2">
          {/*    <!-- Icon --> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 shrink-0"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
            role="graphics-symbol"
            aria-labelledby="title-29 desc-29"
          >
            <title id="title-29">Icon title</title>
            <desc id="desc-29">A more detailed description of the icon</desc>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            />
          </svg>
          {/*    <!-- Headline --> */}
          <h3 className="flex-1 font-semibold">Retrying in 5 sec</h3>
          {/*    <!-- Close button --> */}
          <button
            aria-label="Close"
            className="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide transition duration-300 rounded-full justify-self-center whitespace-nowrap text-slate-200 hover:bg-slate-600 hover:text-slate-100 focus:bg-slate-500 focus:text-slate-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-slate-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-30 desc-30"
              >
                <title id="title-30">Icon title</title>
                <desc id="desc-30">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*  <!-- Body --> */}
        <div className="pl-9 text-slate-300">
          <p>
            An error occurred. Check your disk space and make sure you have
            enough space.
          </p>
        </div>
        {/*  <!-- Actions --> */}
        <div className="flex gap-2 mt-4 pl-9">
          <button className="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded whitespace-nowrap bg-slate-500 hover:bg-slate-600 focus:bg-slate-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-slate-300 disabled:bg-slate-300 disabled:shadow-none">
            <span>proceed</span>
          </button>
          <button className="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide transition duration-300 rounded justify-self-center whitespace-nowrap text-slate-200 hover:bg-slate-600 hover:text-slate-100 focus:bg-slate-500 focus:text-slate-100 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-slate-600 disabled:shadow-none disabled:hover:bg-transparent">
            <span className="relative"> undo </span>
          </button>
        </div>
      </div>
      {/*<!-- End Title & text dark themed notification with action close buttons and leading icon --> */}
    </div>
  )
}
