import React from "react"
import CopyComponent from "../../../../../components/copy/CopyComponent"

export default function NotificationAllPrimaryPreview(props) {
  const {
    NotificationTextBasicPrimary,
    NotificationTextActionButtonPrimary,
    NotificationTitleTextBasicPrimary,
    NotificationTitleTextActionButtonPrimary,
    NotificationIconBasicPrimary,
    NotificationIconActionButtonPrimary,
  } = props

  return (
    <div className="grid grid-cols-1 items-start justify-start gap-x-6 gap-y-12 md:grid-cols-2">
      <CopyComponent
        copyToClipboardCode={NotificationTextBasicPrimary}
        componentName="NotificationTextBasicPrimary"
      >
        {/*<!-- Component: Basic text primary notification with close button --> */}
        <div
          className="relative flex w-80 max-w-full items-center gap-4 overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Text --> */}
          <p className="flex-1">Files deleted</p>
          {/*  <!-- Close button --> */}
          <button
            aria-label="Close"
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-20 desc-20"
              >
                <title id="title-20">Icon title</title>
                <desc id="desc-20">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*<!-- End Basic text primary notification with close button --> */}
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={NotificationTextActionButtonPrimary}
        componentName="NotificationTextActionButtonPrimary"
      >
        {/*<!-- Component: Text primary notification with action and close buttons --> */}
        <div
          className="relative flex w-80 max-w-full items-center gap-4 overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Text --> */}
          <p className="flex-1">Files deleted</p>
          {/*  <!-- Action button --> */}
          <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent">
            <span className="relative"> Undo </span>
          </button>
          {/*  <!-- Close button --> */}
          <button
            aria-label="Close"
            className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
          >
            <span className="relative only:-mx-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                role="graphics-symbol"
                aria-labelledby="title-20 desc-20"
              >
                <title id="title-20">Icon title</title>
                <desc id="desc-20">
                  A more detailed description of the icon
                </desc>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </button>
        </div>
        {/*<!-- End Text primary notification with action and close buttons --> */}
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={NotificationTitleTextBasicPrimary}
        componentName="NotificationTitleTextBasicPrimary"
      >
        {/*<!-- Component: Basic title & text primary notification with close button --> */}
        <div
          className="flex w-80 max-w-full flex-col overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Heading with close button --> */}
          <div className="mb-2 flex items-center gap-4">
            {/*    <!-- Headline --> */}
            <h3 className="flex-1 font-semibold">Retrying in 5 sec</h3>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Icon title</title>
                  <desc id="desc-30">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- Body --> */}
          <div className="text-emerald-100">
            <p>
              An error occurred. Check your disk space and make sure you have
              enough space.
            </p>
          </div>
        </div>
        {/*<!-- End Basic title & text primary notification with close button --> */}
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={NotificationTitleTextActionButtonPrimary}
        componentName="NotificationTitleTextActionButtonPrimary"
      >
        {/*<!-- Component: Title & text primary notification with action and close buttons --> */}
        <div
          className="flex w-80 max-w-full flex-col overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Heading with close button --> */}
          <div className="mb-2 flex items-center gap-4">
            {/*    <!-- Headline --> */}
            <h3 className="flex-1 font-semibold">Retrying in 5 sec</h3>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Icon title</title>
                  <desc id="desc-30">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- Body --> */}
          <div className="text-emerald-100">
            <p>
              An error occurred. Check your disk space and make sure you have
              enough space.
            </p>
          </div>
          {/*  <!-- Actions --> */}
          <div className="mt-4 flex gap-2 pb-1">
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-600/60 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>proceed</span>
            </button>
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent">
              <span className="relative"> undo </span>
            </button>
          </div>
        </div>
        {/*<!-- End Title & text primary notification with action and close buttons --> */}
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={NotificationIconBasicPrimary}
        componentName="NotificationIconBasicPrimary"
      >
        {/*<!-- Component: Basic title & text primary notification with close button and leading icon --> */}
        <div
          className="relative flex w-80 max-w-full flex-col overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Heading with icon & close button --> */}
          <div className="mb-2 flex items-center gap-4">
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              role="graphics-symbol"
              aria-labelledby="title-29 desc-29"
            >
              <title id="title-29">Icon title</title>
              <desc id="desc-29">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {/*    <!-- Headline --> */}
            <h3 className="flex-1 font-semibold">Retrying in 5 sec</h3>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Icon title</title>
                  <desc id="desc-30">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- Body --> */}
          <div className="pl-9 text-emerald-100">
            <p>
              An error occurred. Check your disk space and make sure you have
              enough space.
            </p>
          </div>
        </div>
        {/*<!-- End Basic title & text primary notification with close button and leading icon --> */}
      </CopyComponent>

      <CopyComponent
        copyToClipboardCode={NotificationIconActionButtonPrimary}
        componentName="NotificationIconActionButtonPrimary"
      >
        {/*<!-- Component: Title & text primary notification with action close buttons and leading icon --> */}
        <div
          className="relative flex w-80 max-w-full flex-col overflow-hidden rounded bg-emerald-500 px-4 py-3 text-sm text-white shadow-lg shadow-emerald-400/20 ring-1 ring-inset ring-emerald-600"
          role="status"
        >
          {/*  <!-- Heading with icon & close button --> */}
          <div className="mb-2 flex items-center gap-4">
            {/*    <!-- Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 shrink-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
              role="graphics-symbol"
              aria-labelledby="title-29 desc-29"
            >
              <title id="title-29">Icon title</title>
              <desc id="desc-29">A more detailed description of the icon</desc>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13 10V3L4 14h7v7l9-11h-7z"
              />
            </svg>
            {/*    <!-- Headline --> */}
            <h3 className="flex-1 font-semibold">Retrying in 5 sec</h3>
            {/*    <!-- Close button --> */}
            <button
              aria-label="Close"
              className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent"
            >
              <span className="relative only:-mx-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-30 desc-30"
                >
                  <title id="title-30">Icon title</title>
                  <desc id="desc-30">
                    A more detailed description of the icon
                  </desc>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
          {/*  <!-- Body --> */}
          <div className="pl-9 text-emerald-100">
            <p>
              An error occurred. Check your disk space and make sure you have
              enough space.
            </p>
          </div>
          {/*  <!-- Actions --> */}
          <div className="mt-4 flex gap-2 pl-9">
            <button className="inline-flex h-8 items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-600/60 px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none">
              <span>proceed</span>
            </button>
            <button className="inline-flex h-8 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded px-4 text-xs font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-50 hover:text-emerald-600 focus:bg-emerald-100 focus:text-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:text-emerald-600 disabled:shadow-none disabled:hover:bg-transparent">
              <span className="relative"> undo </span>
            </button>
          </div>
        </div>
        {/*<!-- End Title & text primary notification with action close buttons and leading icon --> */}
      </CopyComponent>
    </div>
  )
}
