import React, { useState } from "react"

// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"
import ogImage from "../../../images/seo/notifications/notifications.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import NotificationAllDarkPreview from "../../../library/components/notification/react/_preview/dark"

import NotificationAllLightPreview from "../../../library/components/notification/react/_preview/light"

import NotificationAllPrimaryPreview from "../../../library/components/notification/react/_preview/primary"

import NotificationAllSecondaryPreview from "../../../library/components/notification/react/_preview/secondary"

import NotificationHeroComponent from "../../../library/components/notification/react/_preview/NotificationHeroComponent"
// *** Text Notifications *** //
// Dark
const NotificationTextBasicDarkJsx = require("!!raw-loader!../../../library/components/notification/react/text/basic-dark.jsx")
const NotificationTextBasicDarkHTML = require("!!raw-loader!../../../library/components/notification/html/text/basic-dark.html")

const NotificationTextActionButtonDarkJsx = require("!!raw-loader!../../../library/components/notification/react/text/action_button-dark.jsx")
const NotificationTextActionButtonDarkHTML = require("!!raw-loader!../../../library/components/notification/html/text/action_button-dark.html")

// Light
const NotificationTextBasicLightJsx = require("!!raw-loader!../../../library/components/notification/react/text/basic-light.jsx")
const NotificationTextBasicLightHTML = require("!!raw-loader!../../../library/components/notification/html/text/basic-light.html")

const NotificationTextActionButtonLightJsx = require("!!raw-loader!../../../library/components/notification/react/text/action_button-light.jsx")
const NotificationTextActionButtonLightHTML = require("!!raw-loader!../../../library/components/notification/html/text/action_button-light.html")

// Primary
const NotificationTextBasicPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/text/basic-primary.jsx")
const NotificationTextBasicPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/text/basic-primary.html")

const NotificationTextActionButtonPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/text/action_button-primary.jsx")
const NotificationTextActionButtonPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/text/action_button-primary.html")

// Secondary
const NotificationTextBasicSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/text/basic-secondary.jsx")
const NotificationTextBasicSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/text/basic-secondary.html")

const NotificationTextActionButtonSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/text/action_button-secondary.jsx")
const NotificationTextActionButtonSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/text/action_button-secondary.html")

// *** Title & Text Notifications *** //
// Dark
const NotificationTitleTextBasicDarkJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/basic-dark.jsx")
const NotificationTitleTextBasicDarkHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/basic-dark.html")

const NotificationTitleTextActionButtonDarkJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/action_button-dark.jsx")
const NotificationTitleTextActionButtonDarkHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/action_button-dark.html")

// Light
const NotificationTitleTextBasicLightJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/basic-light.jsx")
const NotificationTitleTextBasicLightHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/basic-light.html")

const NotificationTitleTextActionButtonLightJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/action_button-light.jsx")
const NotificationTitleTextActionButtonLightHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/action_button-light.html")

// Primary
const NotificationTitleTextBasicPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/basic-primary.jsx")
const NotificationTitleTextBasicPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/basic-primary.html")

const NotificationTitleTextActionButtonPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/action_button-primary.jsx")
const NotificationTitleTextActionButtonPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/action_button-primary.html")

// Secondary
const NotificationTitleTextBasicSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/basic-secondary.jsx")
const NotificationTitleTextBasicSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/basic-secondary.html")

const NotificationTitleTextActionButtonSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/title_text/action_button-secondary.jsx")
const NotificationTitleTextActionButtonSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/title_text/action_button-secondary.html")

// *** Icon Notifications *** //
// Dark
const NotificationIconBasicDarkJsx = require("!!raw-loader!../../../library/components/notification/react/icon/basic-dark.jsx")
const NotificationIconBasicDarkHTML = require("!!raw-loader!../../../library/components/notification/html/icon/basic-dark.html")

const NotificationIconActionButtonDarkJsx = require("!!raw-loader!../../../library/components/notification/react/icon/action_button-dark.jsx")
const NotificationIconActionButtonDarkHTML = require("!!raw-loader!../../../library/components/notification/html/icon/action_button-dark.html")

// Light
const NotificationIconBasicLightJsx = require("!!raw-loader!../../../library/components/notification/react/icon/basic-light.jsx")
const NotificationIconBasicLightHTML = require("!!raw-loader!../../../library/components/notification/html/icon/basic-light.html")

const NotificationIconActionButtonLightJsx = require("!!raw-loader!../../../library/components/notification/react/icon/action_button-light.jsx")
const NotificationIconActionButtonLightHTML = require("!!raw-loader!../../../library/components/notification/html/icon/action_button-light.html")

// Primary
const NotificationIconBasicPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/icon/basic-primary.jsx")
const NotificationIconBasicPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/icon/basic-primary.html")

const NotificationIconActionButtonPrimaryJsx = require("!!raw-loader!../../../library/components/notification/react/icon/action_button-primary.jsx")
const NotificationIconActionButtonPrimaryHTML = require("!!raw-loader!../../../library/components/notification/html/icon/action_button-primary.html")

// Secondary
const NotificationIconBasicSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/icon/basic-secondary.jsx")
const NotificationIconBasicSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/icon/basic-secondary.html")

const NotificationIconActionButtonSecondaryJsx = require("!!raw-loader!../../../library/components/notification/react/icon/action_button-secondary.jsx")
const NotificationIconActionButtonSecondaryHTML = require("!!raw-loader!../../../library/components/notification/html/icon/action_button-secondary.html")

export default function NotificationPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "DarkNotifications",
      title: "Dark Themed Notifications",
      active_tab: 1,
    },
    {
      component_name: "LightNotifications",
      title: "Light Themed",
      active_tab: 1,
    },
    {
      component_name: "PrimaryNotifications",
      title: "Primary",
      active_tab: 1,
    },
    {
      component_name: "SecondaryNotifications",
      title: "Secondary",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Notifications - WindUI Component Library"
        ogtitle="Tailwind CSS Notifications - WindUI Component Library"
        description="The notification component is a user interface element that displays brief, informative messages to users. Built with Tailwind CSS by WindUI."
        ogdescription="The notification component is a user interface element that displays brief, informative messages to users. Built with Tailwind CSS by WindUI."
        url="components/notifications/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Notifications, Notifications, Notification Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Notifications</h1>
          <p>
            The notification component is a user interface element that displays
            brief, informative messages to users. It is typically used to
            provide feedback or notifications about the status of an action or
            to display important information.{" "}
          </p>

          {/* Badges Preview Section */}

          <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <NotificationHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Badges Type Basic Section*/}
          <h3 id="DarkNotifications">Dark Themed Notifications</h3>

          <PreviewBlock
            id="DarkNotifications"
            HtmlComponent={NotificationTextBasicDarkHTML.default}
            JsxComponent={NotificationTextBasicDarkJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16">
              <NotificationAllDarkPreview
                NotificationTextBasicDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationTextBasicDarkHTML.default
                    : NotificationTextBasicDarkJsx.default
                }
                NotificationTextActionButtonDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationTextActionButtonDarkHTML.default
                    : NotificationTextActionButtonDarkJsx.default
                }
                NotificationTitleTextBasicDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationTitleTextBasicDarkHTML.default
                    : NotificationTitleTextBasicDarkJsx.default
                }
                NotificationTitleTextActionButtonDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationTitleTextActionButtonDarkHTML.default
                    : NotificationTitleTextActionButtonDarkJsx.default
                }
                NotificationIconBasicDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationIconBasicDarkHTML.default
                    : NotificationIconBasicDarkJsx.default
                }
                NotificationIconActionButtonDark={
                  activeTabs[0].active_tab === 1
                    ? NotificationIconActionButtonDarkHTML.default
                    : NotificationIconActionButtonDarkJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Basic Icon Section*/}
          <h3 id="LightNotifications">Light Themed Notifications</h3>

          <PreviewBlock
            id="LightNotifications"
            HtmlComponent={NotificationTextBasicLightHTML.default}
            JsxComponent={NotificationTextBasicLightJsx.default}
            getActiveTabs={getActiveTabs}
            className="not-prose"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <NotificationAllLightPreview
                NotificationTextBasicLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationTextBasicLightHTML.default
                    : NotificationTextBasicLightJsx.default
                }
                NotificationTextActionButtonLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationTextActionButtonLightHTML.default
                    : NotificationTextActionButtonLightJsx.default
                }
                NotificationTitleTextBasicLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationTitleTextBasicLightHTML.default
                    : NotificationTitleTextBasicLightJsx.default
                }
                NotificationTitleTextActionButtonLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationTitleTextActionButtonLightHTML.default
                    : NotificationTitleTextActionButtonLightJsx.default
                }
                NotificationIconBasicLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationIconBasicLightHTML.default
                    : NotificationIconBasicLightJsx.default
                }
                NotificationIconActionButtonLight={
                  activeTabs[1].active_tab === 1
                    ? NotificationIconActionButtonLightHTML.default
                    : NotificationIconActionButtonLightJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Rounded Section */}
          <h3 id="PrimaryNotifications">Primary Notifications</h3>

          <PreviewBlock
            id="PrimaryNotifications"
            HtmlComponent={NotificationTextBasicPrimaryHTML.default}
            JsxComponent={NotificationTextBasicPrimaryJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <NotificationAllPrimaryPreview
                NotificationTextBasicPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationTextBasicPrimaryHTML.default
                    : NotificationTextBasicPrimaryJsx.default
                }
                NotificationTextActionButtonPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationTextActionButtonPrimaryHTML.default
                    : NotificationTextActionButtonPrimaryJsx.default
                }
                NotificationTitleTextBasicPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationTitleTextBasicPrimaryHTML.default
                    : NotificationTitleTextBasicPrimaryJsx.default
                }
                NotificationTitleTextActionButtonPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationTitleTextActionButtonPrimaryHTML.default
                    : NotificationTitleTextActionButtonPrimaryJsx.default
                }
                NotificationIconBasicPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationIconBasicPrimaryHTML.default
                    : NotificationIconBasicPrimaryJsx.default
                }
                NotificationIconActionButtonPrimary={
                  activeTabs[2].active_tab === 1
                    ? NotificationIconActionButtonPrimaryHTML.default
                    : NotificationIconActionButtonPrimaryJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Badges Type Rounded with Icon Section */}
          <h3 id="SecondaryNotifications">Secondary Notifications</h3>

          <PreviewBlock
            id="SecondaryNotifications"
            HtmlComponent={NotificationTextBasicSecondaryHTML.default}
            JsxComponent={NotificationTextBasicSecondaryJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-white p-8 sm:p-16 ">
              <NotificationAllSecondaryPreview
                NotificationTextBasicSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationTextBasicSecondaryHTML.default
                    : NotificationTextBasicSecondaryJsx.default
                }
                NotificationTextActionButtonSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationTextActionButtonSecondaryHTML.default
                    : NotificationTextActionButtonSecondaryJsx.default
                }
                NotificationTitleTextBasicSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationTitleTextBasicSecondaryHTML.default
                    : NotificationTitleTextBasicSecondaryJsx.default
                }
                NotificationTitleTextActionButtonSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationTitleTextActionButtonSecondaryHTML.default
                    : NotificationTitleTextActionButtonSecondaryJsx.default
                }
                NotificationIconBasicSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationIconBasicSecondaryHTML.default
                    : NotificationIconBasicSecondaryJsx.default
                }
                NotificationIconActionButtonSecondary={
                  activeTabs[3].active_tab === 1
                    ? NotificationIconActionButtonSecondaryHTML.default
                    : NotificationIconActionButtonSecondaryJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            Notifications (or snackbars) inform users of a process that an app
            has performed or will perform. They appear temporarily, towards the
            bottom of the screen and they shouldn’t interrupt the user
            experience.
          </p>
          <p>
            Notifications communicate messages that are minimally interruptive
            and don’t require user action.
          </p>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>The main elements of the notification component are:</p>
          <ul>
            <li>
              <strong>Container:</strong> notifications are displayed in
              rectangular containers (with y padding of 0.75rem and x padding of
              1rem). Containers have an opaque background, so that text labels
              remain legible.
            </li>
            <li>
              <strong>Supporting text: </strong> the text can consist of a title
              and a description (optional) both 0.75rem in font size.
            </li>
            <li>
              <strong>Close button:</strong> notifications can be dismissed by
              the user. The close button is a 2rem x 2rem icon that is placed at
              the top right corner of the container.
            </li>
            <li>
              <strong>Leading icon:</strong> notifications can have a leading
              icon (optional) that is placed at the top left corner of the
              container. The icon is 1.25rem x 1.25rem and is centered
              vertically and horizontally with the notification title.
            </li>
            <li>
              <strong>Action button:</strong> notifications can have an action
              button (optional) that is placed after the text.
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>
            Notification components are messages that communicate information to
            a user. The WAI-ARIA <code>role=&quot;status&quot;</code> is used to
            display a message to the user in a way that does not interrupt the
            user’s current task and queues the notification until whatever task
            the user is currently working on is complete.
          </p>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
